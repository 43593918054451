import { Link } from "react-router-dom";

function Banner() {
  return (
    <section className="banner">
      <div className="image">
        <picture>
          <source srcSet={`/images/banner-1260w.webp`} type="image/webp" />
          <source srcSet={"/images/banner-1260w.jpg"} type="image/jpeg" />
          <img
            width={1260}
            height={710}
            src="/images/banner-1260w.jpg"
            alt="Shop for plants"
            loading="eager"
          />
        </picture>
      </div>
      <div className="info">
        <h1 className="info__title">
          Green Plants <br />
          All Year Round
        </h1>
        <div className="info__line"></div>
        <p className="info__text">
          Our healthy plants will make a great addition to your home or garden.
          We have a great selection for you, from widely popular to rare and
          exotic. Browse our store and find the perfect fit for your space.
        </p>
        <div>
          <Link to="shop" className="info__button">
            Shop Now
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Banner;
