import React from "react";
import { Link } from "react-router-dom";
import ResetScroll from "../hooks/ResetScroll";

function NotFound() {
  ResetScroll();

  return (
    <div className="pages-notfound">
      <div className="notfound">
        <div>
          <picture>
            <source type="image/webp" srcSet="/images/not-found-256w.webp" />
            <img
              className="logo__icon"
              src="/images/not-found-256w.png"
              alt="Page not found"
              loading="lazy"
              width={256}
              height={256}
            />
          </picture>
        </div>

        <h1>Page not found.</h1>
        <div className="notfound__detail">
          <div>We could not find the page you requested.</div>
          <div>Have a look around to find similar pages.</div>
        </div>
        <div>
          <Link to="/" className="notfound__home-btn">
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
