import { Link } from "react-router-dom";
function Subtotals(props) {
  const { value } = props;

  return (
    <div className="subtotals">
      <div className="subtotals__header">
        <h2>Order Summary</h2>
      </div>
      <div className="subtotals__info">
        <div>
          <h3>Subtotal</h3>
        </div>
        <div className="subtotals__price">{`₱${Number(value).toLocaleString(
          "en-US"
        )}`}</div>
      </div>
      <div className="subtotals__delivery">Delivery calculated at checkout</div>
      <div>
        <Link to="/checkout" className="subtotals__button">
          Checkout
        </Link>
      </div>
    </div>
  );
}

export default Subtotals;
