import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__list">
        <div className="footer__item">
          <h1 className="footer__header">About</h1>
          <p className="footer__content">
            Trellis is a shop created by plant lovers for plant lovers. Taking
            care of plants brings us happiness, and we are fortunate to spread
            this happiness. We envision a future where everyone can enjoy an
            environment that is lively, beautiful, healthy and clean. An
            environment filled with plants!
          </p>
        </div>
        <nav className="footer__item">
          <h1 className="footer__header">Our Products</h1>
          <div className="footer__content">
            <ul>
              <li>
                <Link to="shop?tag=best-seller"> Best Sellers </Link>
              </li>
              <li>
                <Link to="shop?tag=herb"> Herbs </Link>
              </li>
              <li>
                <Link to="shop?tag=orchid"> Orchids </Link>
              </li>
              <li>
                <Link to="shop?tag=succulent"> Succulents </Link>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="footer__item">
          <h1 className="footer__header">Quick Links</h1>
          <div className="footer__content">
            <ul>
              <li>
                <Link to="contact"> Contact Us </Link>
              </li>
              <li>
                <Link to="faq"> FAQ </Link>
              </li>
              <li>
                <Link to="cart"> Cart </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <hr className="footer__line" />
      <div className="footer__end">
        <div className="footer__copyright">© 2022 Trellis</div>
        <div className="footer__socials">
          <a
            href="https://www.facebook.com/"
            className="footer__button"
            aria-label="Go to Facebook"
          >
            <i className="fa-brands fa-facebook fa-3x"></i>
          </a>
          <a
            href="https://twitter.com/"
            className="footer__button"
            aria-label="Go to Twitter"
          >
            <i className="fa-brands fa-twitter fa-3x"></i>
          </a>
          <a
            href="https://www.instagram.com/"
            className="footer__button"
            aria-label="Go to Instagram"
          >
            <i className="fa-brands fa-instagram fa-3x"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
