import axios from "axios";
import { useEffect, useState } from "react";
import { PRODUCT_SIZES, PRODUCT_SIZE_INFO } from "../constants/ShopConstants";
import productsData from "../data/products.json";
import careParamsData from "../data/care-params.json";
import careParamTypesData from "../data/care-param-types.json";
import tagsData from "../data/tags.json";

function getRelationDetails(relationData, responseData) {
  const relationDataList = [].concat(relationData);
  const detailedRelations = relationDataList.map((relationDataItem) => {
    return responseData.find(
      (responseDataItem) => relationDataItem.id === responseDataItem.id
    );
  });

  return detailedRelations;
}

function formatAPIData(
  productsRes,
  careParamsRes,
  careParamsTypesRes,
  tagsRes
) {
  const formattedData = productsRes.data.map(function (productData) {
    const { id, type, attributes, relationships } = productData;
    const formattedProductData = {
      id: id,
      type: type,
      ...attributes,
      price: getPrice(attributes.historicalData),
      tags: getTags(relationships, tagsRes),
      size: getSize(attributes),
      sizeValue: attributes.size,
      page: getPage(attributes),
      ...getCareParams(relationships, careParamsRes, careParamsTypesRes),
    };
    return formattedProductData;
  });
  return formattedData;
}

function getCareParams(relationships, careParamsRes, careParamTypesRes) {
  const detailedParams = getRelationDetails(
    relationships.careParam.data,
    careParamsRes.data
  );

  const careParams = detailedParams.map((careParam) => {
    const careParamType = careParamTypesRes.data.find(
      (type) => careParam.relationships.careParamType.data.id === type.id
    );
    return [careParamType.attributes.name, careParam.attributes.name];
  });

  return Object.fromEntries(new Map(careParams));
}

function getTags(relationships, tagsRes) {
  const detailedTags = getRelationDetails(relationships.tag.data, tagsRes.data);

  const tagNames = detailedTags.map((tags) => tags.attributes.name);
  return tagNames;
}

function getPage(attributes) {
  const page = attributes.name.trim().toLowerCase().replace(/\s+/g, "-");
  return page;
}

function getLastestData(historicalData) {
  const sortedHistorical = [...historicalData].sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const latestData = sortedHistorical[sortedHistorical.length - 1];

  return latestData;
}

function getPrice(historicalData) {
  const latestData = getLastestData(historicalData);
  return latestData.price;
}

function getSize(attributes) {
  const size = PRODUCT_SIZE_INFO.find((sizeInfo) => {
    return attributes.size <= sizeInfo.maxUnit;
  });

  return size?.name || PRODUCT_SIZES.LARGE;
}

function useInventoryList() {
  let [inventoryList, setInventoryList] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
        },
        // withCredentials: true,
      };

      let productsRes = productsData;
      let careParamsRes = careParamsData;
      let careParamsTypesRes = careParamTypesData;
      let tagsRes = tagsData;

      if (process.env.REACT_APP_API !== "mock") {
        const serverURI = process.env.REACT_APP_API_URL;
        const responses = await axios.all([
          axios.get(`${serverURI}/products`, config),
          axios.get(`${serverURI}/products/care-params`, config),
          axios.get(`${serverURI}/products/care-params/types`, config),
          axios.get(`${serverURI}/products/tags`, config),
        ]);

        productsRes = responses[0].data;
        careParamsRes = responses[1].data;
        careParamsTypesRes = responses[2].data;
        tagsRes = responses[3].data;
      }

      const formattedData = formatAPIData(
        productsRes,
        careParamsRes,
        careParamsTypesRes,
        tagsRes
      );
      setInventoryList(formattedData);
      setLoading(false);
    };

    fetchData().catch((error) => {
      console.log("Order page error. Pass me to an error handler:");
      console.log(error);
    });
  }, []);

  return { inventoryList, loading };
}

export default useInventoryList;
