import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Sent from "./pages/Sent";
import Shop from "./pages/Shop";
import Thanks from "./pages/Thanks";
import NotFound from "./pages/NotFound";
import "./styles/app.css";

const clearExpiredCart = () => {
  const SKEY_LAST_VISIT = "lastVisit";
  const SKEY_CART = "cartList";
  const CART_TTL = 3600000; // 1hour

  const currentVisit = Date.now();
  const lastVisit = new Date(Number(localStorage.getItem(SKEY_LAST_VISIT)));
  if (lastVisit) {
    if (CART_TTL < currentVisit - lastVisit) {
      localStorage.removeItem(SKEY_CART);
    }
  }
  localStorage.setItem(SKEY_LAST_VISIT, currentVisit.toString());
};

function App() {
  clearExpiredCart();

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="shop" element={<Shop />} />
          <Route path="product" element={<Product />}>
            <Route path=":productKey" element={<Product />} />
          </Route>
          <Route path="cart" element={<Cart />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="contact" element={<Contact />} />
          <Route path="faq" element={<Faq />} />
          <Route path="sent" element={<Sent />} />
          <Route path="thanks" element={<Thanks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Features />
      <Footer />
    </Router>
  );
}

export default App;
