function Features() {
  const featuresList = [
    {
      name: "feature1",
      image: "/images/feature1-128w.webp",
      alt: "Personally Delivered",
      label: "Personally Delivered",
      desc: "We have our own logistics team to deliver the plants to your doorstep.",
    },
    {
      name: "feature2",
      image: "/images/feature2-128w.webp",
      alt: "30-Day Guarantee",
      label: "30-Day Guarantee",
      desc: "To ensure your satisfaction, we will replace any damaged goods for free.",
    },
    {
      name: "feature3",
      image: "/images/feature3-128w.webp",
      alt: "Secure Payments",
      label: "Secure Payments",
      desc: "Our shop supports mutiple secure payment methods.",
    },
    {
      name: "feature4",
      image: "/images/feature4-128w.webp",
      alt: "Local Support",
      label: "Local Support",
      desc: "Have concerns? Chat with our local support team.",
    },
  ];

  return (
    <aside className="features">
      <div className="features__list">
        {featuresList.map((item) => (
          <div key={item.name} className="features__item">
            <picture>
              <source type="image/webp" srcSet={item.image} />
              <img
                className="features__icon"
                alt={item.alt}
                src={item.image.replace(".webp", ".png")}
                loading="lazy"
                width={128}
                height={128}
              />
            </picture>
            <div>
              <h1 className="features__label">
                {" "}
                {item.label.replace(" ", "\n")}{" "}
              </h1>
              <hr className="features__line" />
              <p className="features__desc">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </aside>
  );
}

export default Features;
