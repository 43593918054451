import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { SERVER_URL } from "../constants/AppURL";
import { CARE_PARAMS, PRODUCT_TAGS } from "../constants/ShopConstants";
import QuantityControl from "../control/QuantityControl";
import ResetScroll from "../hooks/ResetScroll";
import useInventoryList from "../hooks/UseInventoryList";
import Modal from "./../components/Modal";

const SHOP_MIN_QTY = Number(process.env.REACT_APP_SHOP_MIN_QTY);
const SHOP_MAX_QTY = Number(process.env.REACT_APP_SHOP_MAX_QTY);
const SKEY_CART = "cartList";

function Product() {
  const { productKey } = useParams();
  const [orderQty, setOrderQty] = useState(SHOP_MIN_QTY);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { inventoryList } = useInventoryList();
  const product = inventoryList.find((item) => item.page === productKey);
  const cartList = JSON.parse(localStorage.getItem(SKEY_CART)) || [];
  const location = useLocation();
  const { navigationTag } = location.state || {};

  ResetScroll();

  const updateCartList = () => {
    if (!product) return;

    const order = cartList.find((item) => item.productKey === product.id);

    const newOrder = {
      productKey: product.id,
      quantity: order ? orderQty + order.quantity : orderQty,
    };
    const newCartList = cartList.filter(
      (item) => !(item.productKey === product.id)
    );
    newCartList.push(newOrder);

    localStorage.setItem(SKEY_CART, JSON.stringify(newCartList));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const modalCloseBtnOnClick = () => {
    hideModal();
  };

  const onCartBtnClick = () => {
    updateCartList();
    showModal();
  };

  const onQuantityBtnClick = (increment) => {
    let nQuantity = orderQty + increment;
    nQuantity = nQuantity < SHOP_MIN_QTY ? SHOP_MIN_QTY : nQuantity;
    nQuantity = nQuantity > SHOP_MAX_QTY ? SHOP_MAX_QTY : nQuantity;

    setOrderQty(nQuantity);
  };

  const careParams = product
    ? [
        {
          header: "Water",
          values: CARE_PARAMS.find((item) => item.id === product.water) || {},
          iconType: "fa-solid fa-droplet fa-1x",
        },
        {
          header: "Light",
          values: CARE_PARAMS.find((item) => item.id === product.light) || {},
          iconType: "fa-solid fa-sun",
        },
        {
          header: "Difficulty",
          values:
            CARE_PARAMS.find((item) => item.id === product.difficulty) || {},
          iconType: "fa-solid fa-star",
        },
      ]
    : [];

  const getTagName = (tagName) => {
    const tagLabels = [
      {
        name: PRODUCT_TAGS.ALL_PLANTS,
        text: "All Plants",
      },
      {
        name: PRODUCT_TAGS.BEST_SELLERS,
        text: "Best Sellers",
      },
      {
        name: PRODUCT_TAGS.HERBS,
        text: "Herbs",
      },
      {
        name: PRODUCT_TAGS.ORCHIDS,
        text: "Orchids",
      },
      {
        name: PRODUCT_TAGS.SUCCULENTS,
        text: "Succulents",
      },
      {
        name: PRODUCT_TAGS.BEGINNERS,
        text: "Best For Beginners",
      },
      {
        name: PRODUCT_TAGS.PET_FRIENDLY,
        text: "Pet Friendly Plants",
      },
    ];

    const labelItem = tagLabels.find((label) => label.name === tagName);
    return labelItem.text;
  };

  return (
    product && (
      <div className="product">
        <div className="product__nav">
          <Link to="/shop" className="product__nav-button">
            All Plants
          </Link>
          {navigationTag && (
            <Link
              to={`/shop?tag=${navigationTag}`}
              className="product__nav-button"
            >
              {getTagName(navigationTag)}
            </Link>
          )}
        </div>
        <div className="product__body">
          <div className="product__preview">
            <picture>
              <source
                srcSet={`${SERVER_URL}/images/${product.image}`}
                type="image/webp"
              />
              <source
                srcSet={`${SERVER_URL}/images/${product.image?.replace(
                  ".webp",
                  ".jpg"
                )}`}
                type="image/jpeg"
              />
              <img
                alt={`Preview of ${product.name}`.toLowerCase()}
                src={`${SERVER_URL}/images/${product.image?.replace(
                  ".webp",
                  ".jpg"
                )}`}
                width={400}
                height={518}
              />
            </picture>
          </div>
          <div className="product__details">
            <h2>{product.name}</h2>
            <div className="product__price">{`₱${Number(
              product.price
            ).toLocaleString("en-US")}`}</div>
            <div className="product__cart">
              <QuantityControl
                value={orderQty}
                onQuantityBtnClick={onQuantityBtnClick}
                max={SHOP_MAX_QTY}
                min={SHOP_MIN_QTY}
              />
              <button className="product__cart-btn" onClick={onCartBtnClick}>
                Add to cart
              </button>
            </div>
            <div className="product__desc">
              <h2>Description</h2>
              <p>{product.description}</p>
            </div>
            <div className="care-list">
              <h2 className="care-list__header"> Plant Care </h2>
              <ul>
                {careParams.map((item) => {
                  return (
                    <li key={item.header} className="care-list__item">
                      <h3 className="care-list__type-header">{item.header}</h3>
                      <div className="">
                        <span className="care-list__icon-group">
                          <i className={item.iconType}></i>
                        </span>
                        <span className="care-list__type-desc">
                          {item.values.text}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <Modal
          product={product}
          isVisible={isModalVisible}
          closeBtnOnClick={modalCloseBtnOnClick}
        />
      </div>
    )
  );
}

export default Product;
