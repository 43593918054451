import { useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function Header() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const term = searchParams.get("query") || "";
    setSearchTerm(term);
  }, [searchParams]);

  let navigate = useNavigate();

  const onSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const onSearchSubmit = (event) => {
    event.preventDefault();

    let params = { query: searchTerm };
    navigate({
      pathname: "/shop",
      search: `?${createSearchParams(params)}`,
    });
  };

  return (
    <header className="header">
      <div className="first">
        <Link to="/" className="logo">
          <picture>
            <source type="image/webp" srcSet="/images/logo-250w.webp" />
            <img
              className="logo__icon"
              src="/images/logo-250w.png"
              alt="Spiralis Logo, Go to home page"
              loading="lazy"
              width={250}
              height={250}
            />
          </picture>
          <span className="logo__text">Trellis</span>
        </Link>
        <div className="actions">
          <form className="search" onSubmit={onSearchSubmit}>
            <input
              className="search__field"
              placeholder="Search for products"
              value={searchTerm}
              onChange={onSearchInput}
            ></input>
            <button
              className="search__button"
              type="submit"
              aria-label="Submit search query"
            >
              <i className="fa-solid fa-magnifying-glass fa-xl"></i>
            </button>
          </form>
          <Link to="cart" className="actions__cart" aria-label="Go to cart">
            <i className="fa-solid fa-cart-shopping fa-xl"></i>
          </Link>
        </div>
      </div>
      <div className="second">
        <form className="search" onSubmit={onSearchSubmit}>
          <input
            className="search__field"
            placeholder="Search for products"
            value={searchTerm}
            onChange={onSearchInput}
          ></input>
          <button
            className="search__button"
            type="submit"
            aria-label="Submit search query"
          >
            <i className="fa-solid fa-magnifying-glass fa-xl"></i>
          </button>
        </form>
      </div>
    </header>
  );
}

export default Header;
