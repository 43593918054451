import React from "react";

function RadioSet(props) {
  const { itemProps, onChange, disabled } = props;

  return (
    <div className="radioset">
      <ul>
        {itemProps.map((item) => (
          <li key={item.id}>
            <button
              className={
                disabled
                  ? "radioset__option radioset__option--inactive"
                  : "radioset__option radioset__option--active"
              }
              onClick={(event) => onChange(event, item.id)}
              disabled={disabled}
            >
              <i
                className={
                  item.active
                    ? "fa-regular fa-circle-dot"
                    : "fa-regular fa-circle"
                }
              ></i>
              {item.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RadioSet;
