import React from "react";
import { Link } from "react-router-dom";
import ResetScroll from "../hooks/ResetScroll";

function Sent() {
  ResetScroll();

  return (
    <div className="pages-sent">
      <div className="sent">
        <div>
          <picture>
            <source type="image/webp" srcSet="/images/sent-256w.webp" />
            <img
              src="/images/sent-256w.png"
              alt="Message sent"
              loading="lazy"
              width={256}
              height={256}
            />
          </picture>
        </div>

        <h1>Thank you for reaching out!</h1>
        <div className="sent__detail">
          <div>Your inquiry was sent successfully. </div>
          <div>Our team will get in touch shortly.</div>
        </div>
        <div>
          <Link to="/" className="sent__home-btn">
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sent;
