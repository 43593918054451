import fuzzysort from "fuzzysort";
import { Link } from "react-router-dom";
import { SORT_ORDER } from "../constants/ShopConstants";
import useInventoryList from "./../hooks/UseInventoryList";
import { SERVER_URL } from "../constants/AppURL";
import LoadImage from "./LoadImage";

function Inventory(props) {
  const { query, sortOrder, tag, sizes } = props;
  const { inventoryList, loading } = useInventoryList();
  const noQueryResults1 = `We couldn't find anything for "${query}".`;
  const noQueryResults2 = "Try a different search term.";
  const noFilterResults1 =
    "We couldn't find a product that matches all of your filters.";
  const noFilterResults2 = "Try a different set of filters";

  let noResultsMsg1 = noQueryResults1;
  let noResultsMsg2 = noQueryResults2;

  let searchData = [...inventoryList];
  if (query) {
    searchData = fuzzysort.go(query, inventoryList, { key: "name" });
    searchData = searchData.map((result) => result.obj);
  }

  if (tag) {
    searchData = searchData.filter((item) => item.tags.includes(tag));
  }

  if (sizes.length > 0) {
    searchData = searchData.filter((item) => sizes.includes(item.size));
    noResultsMsg1 = noFilterResults1;
    noResultsMsg2 = noFilterResults2;
  }

  searchData = sortSearchData(searchData, sortOrder);

  return (
    <section className="inventory">
      {loading ? (
        <div className="inventory__loading">
          <div>
            <span className="inventory__loader"></span>
          </div>
          <h2 className="inventory__loading-msg1">Loading</h2>
          <div className="inventory__loading-msg2">
            Please wait while we fetch results.
          </div>
        </div>
      ) : searchData.length < 1 ? (
        <div className="inventory__empty">
          <h2 className="inventory__empty-msg1">{noResultsMsg1}</h2>
          <div className="inventory__empty-msg2">{noResultsMsg2}</div>
        </div>
      ) : (
        <div className="inventory__results">
          {searchData.map((item) => (
            <div key={item.id}>
              <Link
                to={`/product/${item.page}`}
                state={{ navigationTag: tag }}
                className="inventory__card"
              >
                <LoadImage
                  data={{
                    width: 400,
                    height: 518,
                    backupSrc: `${SERVER_URL}/images/${item.image.replace(
                      ".webp",
                      ".jpg"
                    )}`,
                  }}
                  src={`${SERVER_URL}/images/${item.image}`}
                  alt={`Buy ${item.name}`}
                  className="inventory__image"
                  loading="lazy"
                />
                <h2 className="inventory__label">{item.name}</h2>
                <div className="inventory__price">
                  {" "}
                  {`₱${Number(item.price).toLocaleString("en-US")}`}{" "}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

function sortSearchData(searchData, sortOrder) {
  switch (sortOrder) {
    case SORT_ORDER.NEWEST:
      searchData.sort(sortCompare("storeDate"));
      break;
    case SORT_ORDER.LOWEST_PRICE:
      searchData.sort(sortCompare("price", true));
      break;
    case SORT_ORDER.HIGHEST_PRICE:
      searchData.sort(sortCompare("price"));
      break;
    default:
      searchData.sort(sortCompare("sold"));
      break;
  }

  return searchData;
}

function sortCompare(field, ascending = false) {
  return function (item1, item2) {
    return ascending
      ? item1[field] - item2[field]
      : item2[field] - item1[field];
  };
}

export default Inventory;
