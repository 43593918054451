import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Loader from "./Loader";

const mockMode = process.env.REACT_APP_API === "mock";

const initialOptions = {
  "client-id":
    "AQcLtYK4M9pegd9W_LMsTvi5uWxzhjjDeW-zd4ePyv3x8kkjrqkDdaM7h0v9Ust4lG380T-6-59vJoLp",
  "components": "buttons",
  "currency": "PHP",
  "disable-funding": "card,credit",
};

function PayPalCheckout(props) {
  const {
    inventoryList,
    cartList,
    customerInfo,
    createServerOrder,
    completePurchase,
    shippingFee,
  } = props;

  const getProduct = (productKey) => {
    const product = inventoryList.find((item) => item.id === productKey);
    return product;
  };

  const calculateSubtotals = () => {
    let subtotal = 0;
    if (inventoryList.length > 0 && cartList.length > 0) {
      subtotal = cartList.reduce(function (workingSubTotal, item2) {
        const product = getProduct(item2.productKey);
        return workingSubTotal + product.price * item2.quantity;
      }, 0);
    }
    return subtotal;
  };

  const createPayPalOrder = (data, actions) => {
    const itemList = cartList.map((cartItem) => {
      const product = getProduct(cartItem.productKey);
      const item = {
        name: product.name,
        unit_amount: {
          currency_code: "PHP",
          value: product.price.toString(),
        },
        quantity: cartItem.quantity,
      };
      return item;
    });

    const totalValue = calculateSubtotals();

    const shippingValue = shippingFee;
    const address1 = `${customerInfo.streetAddress}`;
    const province = `${customerInfo.province}`;
    const city = `${customerInfo.city}`;
    const postal_code = `${customerInfo.postCode}`;
    const shippingName = `${customerInfo.lastName}, ${customerInfo.firstName}`;

    const amountValue = totalValue + shippingValue;
    const invoiceID = mockMode ? "" : data.data.id;

    return actions.order.create({
      purchase_units: [
        {
          invoice_id: invoiceID,
          amount: {
            currency_code: "PHP",
            value: amountValue.toString(),
            breakdown: {
              item_total: {
                currency_code: "PHP",
                value: totalValue.toString(),
              },
              shipping: {
                currency_code: "PHP",
                value: shippingValue.toString(),
              },
            },
          },
          shipping: {
            address: {
              country_code: "PH",
              address_line_1: address1,
              admin_area_1: province,
              admin_area_2: city,
              postal_code: postal_code,
            },
            name: {
              full_name: shippingName,
            },
            type: "SHIPPING",
          },
          items: itemList,
        },
      ],
    });
  };

  const createOrder = async (data, actions) => {
    const resOrder = mockMode ? {} : await createServerOrder();

    return createPayPalOrder(resOrder.data, actions);
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (orderData) {
      completePurchase();
    });
  };

  const ButtonWrapper = () => {
    const [{ isPending }] = usePayPalScriptReducer();

    return isPending ? (
      <Loader />
    ) : (
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />
    );
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <ButtonWrapper />
    </PayPalScriptProvider>
  );
}

export default PayPalCheckout;
