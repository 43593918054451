import React, { useState, useEffect } from "react";

const placeholderSrc = (width = 1, height = 1) =>
  `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

function LoadImage(props) {
  const [loadedImgSrc, setLoadedImgSrc] = useState(
    placeholderSrc(props.data.width, props.data.height)
  );

  useEffect(() => {
    const img = new Image();
    img.src = props.src || "";

    img.onload = () => {
      setLoadedImgSrc(img.src);
    };

    img.onerror = () => {
      const backupSrc = props.data.backupSrc;
      const backupImg = new Image();
      backupImg.src = backupSrc || "";

      backupImg.onload = () => {
        setLoadedImgSrc(backupImg.src);
      };

      backupImg.onerror = () => {
        setLoadedImgSrc(placeholderSrc(props.data.width, props.data.height));
      };
    };
  });

  return (
    <img
      id={props.id}
      src={loadedImgSrc}
      alt={props.alt}
      className={props.className}
      loading={props.loading}
      width={props.data.width}
      height={props.data.height}
    />
  );
}

export default LoadImage;
