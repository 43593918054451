import { useState } from "react";
import { Link } from "react-router-dom";
import Subtotals from "../components/Subtotals";
import QuantityControl from "../control/QuantityControl";
import useInventoryList from "./../hooks/UseInventoryList";
import ResetScroll from "../hooks/ResetScroll";
import { SERVER_URL } from "../constants/AppURL";

const SHOP_MIN_QTY = Number(process.env.REACT_APP_SHOP_MIN_QTY);
const SHOP_MAX_QTY = Number(process.env.REACT_APP_SHOP_MAX_QTY);
const SKEY_CART = "cartList";

function Cart() {
  const { inventoryList } = useInventoryList();
  const orderList = JSON.parse(localStorage.getItem(SKEY_CART)) || [];
  const [cartList, setCartList] = useState(orderList);

  ResetScroll();

  const onQuantityBtnClick = (increment, cartItem) => {
    updateCartList(cartItem.quantity + increment, cartItem.productKey);
  };

  const onDeleteBtnClick = (cartItem) => {
    const nCartList = cartList.filter(
      (item) => item.productKey !== cartItem.productKey
    );

    setCartList(nCartList);
    localStorage.setItem(SKEY_CART, JSON.stringify(nCartList));
  };

  const updateCartList = (newQuantity, productKey) => {
    const nCartList = cartList.map((item) => {
      let nQuantity = item.quantity;
      if (item.productKey === productKey) {
        nQuantity = newQuantity < SHOP_MIN_QTY ? SHOP_MIN_QTY : newQuantity;
        nQuantity = newQuantity > SHOP_MAX_QTY ? SHOP_MAX_QTY : newQuantity;
      }
      return { ...item, quantity: nQuantity };
    });

    setCartList(nCartList);
    localStorage.setItem(SKEY_CART, JSON.stringify(nCartList));
  };

  const getProduct = (productKey) => {
    const product = inventoryList.find((item) => item.id === productKey);
    return product;
  };

  if (inventoryList.length > 0 && cartList.length > 0) {
    cartList.sort((item1, item2) => {
      const product1 = getProduct(item1.productKey) || {};
      const product2 = getProduct(item2.productKey) || {};
      return product1.name.localeCompare(product2.name);
    });
  }

  const calculateSubtotals = () => {
    let subtotal = 0;
    if (inventoryList.length > 0 && cartList.length > 0) {
      subtotal = cartList.reduce(function (workingSubTotal, item2) {
        const product = getProduct(item2.productKey);
        return workingSubTotal + product.price * item2.quantity;
      }, 0);
    }
    return subtotal;
  };

  const calculateTotalItems = () => {
    const totalItems = cartList.reduce((total, item) => {
      return (total += item.quantity);
    }, 0);

    let suffix = totalItems === 1 ? "item" : "items";

    return `${totalItems} ${suffix}`;
  };

  const renderCartList = () => {
    return (
      <div className="list">
        {cartList.map((cartItem) => {
          const product = getProduct(cartItem.productKey);
          return (
            product && (
              <div key={product.id} className="list__row">
                <div className="list__header">
                  <button
                    onClick={() => onDeleteBtnClick(cartItem)}
                    aria-label="Remove this item from the cart"
                  >
                    <i className="fa-solid fa-x" />
                  </button>
                </div>
                <div className="list__content">
                  <div className="list__preview">
                    <Link to={`/product/${product.page}`}>
                      <picture>
                        <source
                          srcSet={`${SERVER_URL}/images/${product.image}`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${SERVER_URL}/images/${product.image?.replace(
                            ".webp",
                            ".jpg"
                          )}`}
                          type="image/jpeg"
                        />
                        <img
                          alt={`Preview of ${product.name}`.toLowerCase()}
                          src={`${SERVER_URL}/images/${product.image?.replace(
                            ".webp",
                            ".jpg"
                          )}`}
                          width={400}
                          height={518}
                        />
                      </picture>
                    </Link>
                  </div>
                  <div className="list__detail">
                    <div className="list__product-label">
                      <h2>{product.name}</h2>
                    </div>
                    <div className="list__quantity-control">
                      <QuantityControl
                        value={cartItem.quantity}
                        onQuantityBtnClick={(increment) => {
                          onQuantityBtnClick(increment, cartItem);
                        }}
                        max={SHOP_MAX_QTY}
                        min={SHOP_MIN_QTY}
                      />
                      <div className="list__subtotal">
                        {`₱${(
                          Number(product.price) * cartItem.quantity
                        ).toLocaleString("en-US")}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const renderEmptyCart = () => {
    return (
      <div className="empty-list">
        <div>
          <picture>
            <source type="image/webp" srcSet="/images/empty-cart-256w.webp" />
            <img
              src="/images/empty-cart-256w.png"
              alt="Your cart is empty"
              loading="lazy"
              width={256}
              height={256}
            />
          </picture>
        </div>
        <h1>Your cart is empty</h1>
        <div className="empty-list__detail">
          <div>You don't have any items in your cart.</div>
        </div>
        <div>
          <Link to="/shop" className="empty-list__shop-btn">
            Browse Trellis
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="pages-cart">
      <div
        className={
          cartList.length > 0 ? "main main--default" : "main main--empty"
        }
      >
        <div className="title">
          <div className="title__label">
            <h2>Shopping Cart</h2>
          </div>
          <div className="title__count">{calculateTotalItems()}</div>
        </div>
        {cartList.length > 0 ? renderCartList() : renderEmptyCart()}
      </div>
      {cartList.length > 0 && <Subtotals value={calculateSubtotals()} />}
    </div>
  );
}

export default Cart;
