import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../constants/RegexValidation";
import ResetScroll from "../hooks/ResetScroll";

const initFormInfo = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
};

function Contact() {
  const [formInfo, setFormInfo] = useState(initFormInfo);
  const [inputErrors, setInputErrors] = useState([]);
  const navigate = useNavigate();

  ResetScroll();

  const onFormChange = (event) => {
    const updatedFormInfo = { ...formInfo };
    updatedFormInfo[event.target.name] = event.target.value;

    setFormInfo(updatedFormInfo);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (inputErrors.length > 0) return;

    const errors = validateInputComplete(formInfo);
    if (errors.length > 0) {
      setInputErrors(errors);
      return;
    }

    navigate("/sent");
  };

  const cleanFormInfo = (formInfo) => {
    const cleaned = Object.keys(formInfo).reduce((accumulator, key) => {
      accumulator[key] = formInfo[key].trim();
      return accumulator;
    }, {});
    return cleaned;
  };

  const validateInputComplete = (info) => {
    const cleanInfo = cleanFormInfo(info);

    let errors = [];
    Object.keys(cleanInfo).forEach((key) => {
      if (!cleanInfo[key]) {
        errors.push({
          name: key,
          message: "Please fill in this field.",
        });
      }
    });

    return errors;
  };

  const validateInputValues = (info) => {
    let errors = [];

    if (info.email) {
      const email = info.email.toLowerCase();
      const emailRE = EMAIL_REGEX;
      const validEmail = email.match(emailRE);
      if (!validEmail) {
        errors.push({
          name: "email",
          message: "Please enter a valid email address.",
        });
      }
    }

    return errors;
  };

  const onInputBlur = () => {
    const errors = validateInputValues(formInfo);
    setInputErrors(errors);
  };

  const getInfo = (name) => {
    const fieldInfo = [
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
      },
    ];

    return fieldInfo.find((item) => item.name === name);
  };

  const getError = (name) => {
    return inputErrors.find((error) => error.name === name);
  };

  const renderInputField = (info) => {
    return (
      <Fragment>
        <input
          className={
            getError(info.name)
              ? "message-form__field message-form__field--error"
              : "message-form__field message-form__field--default"
          }
          type={info.type}
          name={info.name}
          value={formInfo[info.name]}
          placeholder={info.placeholder}
          onChange={(event) => onFormChange(event)}
          onBlur={onInputBlur}
        />
        {getError(info.name) && (
          <div className="message-form__field-error-info">
            {getError(info.name).message}
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div className="pages-contact">
      <div className="page-info">
        <h1>Contact Us</h1>
        <p>Need to get in touch with us?</p>
        <p>Fill out the form with your inquiry, and send us a message.</p>
        <p>We'd love to hear from you.</p>
      </div>
      <form className="message-form" action="" onSubmit={onFormSubmit}>
        <label>Full Name:</label>
        {renderInputField(getInfo("firstName"))}
        {renderInputField(getInfo("lastName"))}
        <label>Email:</label>
        {renderInputField(getInfo("email"))}
        <label>What can we help you with?</label>
        <textarea
          className={
            getError("message")
              ? "message-form__textarea message-form__textarea--error"
              : "message-form__textarea message-form__textarea--default"
          }
          name="message"
          value={formInfo.message}
          placeholder="Your Message"
          onChange={(event) => onFormChange(event)}
          rows="4"
          onBlur={onInputBlur}
        ></textarea>
        {getError("message") && (
          <div className="message-form__field-error-info">
            {getError("message").message}
          </div>
        )}
        <button className="message-form__btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default Contact;
