function QuantityControl(props) {
  const { value, onQuantityBtnClick, max, min } = props;

  let maxBtnActive = value >= max ? "quantity-control__btn--inactive" : "";
  let minBtnActive = value <= min ? "quantity-control__btn--inactive" : "";

  return (
    <div className="quantity-control">
      <button
        className={`quantity-control__btn ${minBtnActive}`}
        onClick={() => {
          onQuantityBtnClick(-1);
        }}
        aria-label="Remove one less of this item"
      >
        <i className="fa-solid fa-minus fa-xl"></i>
      </button>
      <div className="quantity-control__value">{value}</div>
      <button
        className={`quantity-control__btn ${maxBtnActive}`}
        onClick={() => {
          onQuantityBtnClick(1);
        }}
        aria-label="Add one more of this item"
      >
        <i className="fa-solid fa-plus fa-xl"></i>
      </button>
    </div>
  );
}

export default QuantityControl;
