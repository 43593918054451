//https://www.w3schools.com/howto/howto_js_autocomplete.asp
import { useRef, useState } from "react";
import useOutsideAlerter from "../hooks/UseOutsideAlerter";

function AutoComplete(props) {
  const [optionList, setOptionList] = useState([]);
  const { selections, value, onChange, inputError } = props;
  const wrapperRef = useRef(null);
  const inputRef = useRef();

  useOutsideAlerter(wrapperRef, () => {
    setOptionList([]);
  });

  const onItemClick = (itemValue) => {
    onChange("province", itemValue, true);
    setOptionList([]);

    inputRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  const onInputChange = (event) => {
    const inputValue = event.target.value;
    onChange("province", inputValue, true);
    const filteredList = filterListByInput(selections, inputValue);
    setOptionList(filteredList);
  };

  const filterListByInput = (list, inputTxt) => {
    const filteredList = list.filter((listItem) => {
      return (
        inputTxt.length > 0 &&
        listItem.substr(0, inputTxt.length).toUpperCase() ===
          inputTxt.toUpperCase()
      );
    });

    return filteredList;
  };

  return (
    <div className="autocomplete" ref={wrapperRef}>
      <div className="autocomplete__input">
        <input
          ref={inputRef}
          className={
            inputError
              ? "autocomplete__field autocomplete__field--error"
              : "autocomplete__field autocomplete__field--default"
          }
          id="inputField"
          type="text"
          name="province"
          placeholder="Province / State"
          value={value}
          onChange={onInputChange}
          autoComplete="off"
        ></input>
      </div>
      {optionList.length >= 1 && (
        <div className="autocomplete__option-list">
          {optionList.map((item) => {
            return (
              <div
                className="autocomplete__option-item"
                key={item}
                onClick={() => onItemClick(item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AutoComplete;
