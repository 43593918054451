import { Link } from "react-router-dom";
import LoadImage from "./LoadImage";

function Coverline(props) {
  const coverlineList = [
    {
      name: "coverline1",
      label: "Popular",
      image: "/images/coverline1-240w.webp",
      alt: "Shop for popular plants",
      linkTo: "/shop?tag=best-seller",
    },
    {
      name: "coverline2",
      label: "Herbs",
      image: "/images/coverline2-240w.webp",
      alt: "Shop for garden herbs",
      linkTo: "/shop?tag=herb",
    },
    {
      name: "coverline3",
      label: "Orchids",
      image: "/images/coverline3-240w.webp",
      alt: "Shop for orchids",
      linkTo: "/shop?tag=orchid",
    },
    {
      name: "coverline4",
      label: "Succulents",
      image: "/images/coverline4-240w.webp",
      alt: "Shop for succulents",
      linkTo: "/shop?tag=succulent",
    },
  ];

  return (
    <section className="coverline">
      <h1 className="coverline__header">Featured</h1>
      <div className="coverline__list">
        {coverlineList.map((item) => {
          return (
            <div key={item.name} className="coverline__item">
              <Link to={item.linkTo} className="inventory__card">
                <LoadImage
                  data={{
                    width: 240,
                    height: 360,
                    backupSrc: item.image.replace(".webp", ".jpg"),
                  }}
                  src={item.image}
                  alt={`Buy ${item.name}`}
                  className="inventory__image"
                  loading="lazy"
                />
                <div className="coverline__label">
                  <h2>{item.label}</h2>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
}
export default Coverline;
