export const SORT_ORDER = {
  POPULARITY: "Most Popular",
  NEWEST: "Newest",
  LOWEST_PRICE: "Lowest Price",
  HIGHEST_PRICE: "Highest Price",
};

export const PRODUCT_TAGS = {
  ALL_PLANTS: "all plants",
  BEST_SELLERS: "best-seller",
  HERBS: "herb",
  ORCHIDS: "orchid",
  SUCCULENTS: "succulent",
  BEGINNERS: "beginner",
  PET_FRIENDLY: "pet-friendly",
};

export const PRODUCT_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const PRODUCT_SIZE_INFO = [
  {
    name: PRODUCT_SIZES.SMALL,
    maxUnit: 3,
  },
  {
    name: PRODUCT_SIZES.MEDIUM,
    maxUnit: 5,
  },
  {
    name: PRODUCT_SIZES.LARGE,
  },
];

export const CARE_PARAMS = [
  //--- Light
  {
    id: "light_1",
    type: "light",
    text: "Low",
  },
  {
    id: "light_2",
    type: "light",
    text: "Medium",
  },
  {
    id: "light_3",
    type: "light",
    text: "Bright",
  },
  {
    id: "light_4",
    type: "light",
    text: "Direct",
  },
  //--- Water
  {
    id: "water_1",
    type: "water",
    text: "Once a week",
  },
  {
    id: "water_2",
    type: "water",
    text: "1-2 times a week",
  },
  {
    id: "water_3",
    type: "water",
    text: "2-3 times a week",
  },
  {
    id: "water_4",
    type: "water",
    text: "Daily",
  },
  //--- Difficulty
  {
    id: "difficulty_1",
    type: "difficulty",
    text: "Easy",
  },
  {
    id: "difficulty_2",
    type: "difficulty",
    text: "Moderate",
  },
  {
    id: "difficulty_3",
    type: "difficulty",
    text: "Expert",
  },
];
