import React from "react";
import { Link } from "react-router-dom";
import ResetScroll from "../hooks/ResetScroll";

function Thanks() {
  ResetScroll();

  return (
    <div className="pages-thanks">
      <div className="thanks">
        <div>
          <picture>
            <source type="image/webp" srcSet="/images/thanks-256w.webp" />
            <img
              src="/images/thanks-256w.png"
              alt="Thank you"
              loading="lazy"
              width={256}
              height={256}
            />
          </picture>
        </div>

        <h1>Thank you for your purchase!</h1>
        <div className="thanks__detail">
          <div>Your order has been placed successfully.</div>
          <div>A receipt will be emailed to you shortly.</div>
        </div>
        <div>
          <Link to="/" className="thanks__home-btn">
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Thanks;
