import { useState } from "react";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Inventory from "../components/Inventory";
import ListModal from "../components/ListModal";
import {
  PRODUCT_SIZES,
  PRODUCT_TAGS,
  SORT_ORDER,
} from "../constants/ShopConstants";
import ResetScroll from "../hooks/ResetScroll";

const navLinkData = [
  {
    to: "/shop",
    name: PRODUCT_TAGS.ALL_PLANTS,
    text: "All Plants",
    active: false,
  },
  {
    name: PRODUCT_TAGS.BEST_SELLERS,
    text: "Best Sellers",
    active: false,
  },
  {
    name: PRODUCT_TAGS.HERBS,
    text: "Herbs",
    active: false,
  },
  {
    name: PRODUCT_TAGS.ORCHIDS,
    text: "Orchids",
    active: false,
  },
  {
    name: PRODUCT_TAGS.SUCCULENTS,
    text: "Succulents",
    active: false,
  },
  {
    name: PRODUCT_TAGS.BEGINNERS,
    text: "Best For Beginners",
    active: false,
  },
  {
    name: PRODUCT_TAGS.PET_FRIENDLY,
    text: "Pet Friendly Plants",
    active: false,
  },
];

const sizeListData = [
  {
    name: PRODUCT_SIZES.SMALL,
    text: "Small",
    active: false,
  },
  {
    name: PRODUCT_SIZES.MEDIUM,
    text: "Medium",
    active: false,
  },
  {
    name: PRODUCT_SIZES.LARGE,
    text: "Large",
    active: false,
  },
];

function Shop() {
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.POPULARITY);
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryModalVisible, setCategoryModalVisible] = useState(false);
  const [sizeModalVisible, setSizeModalVisible] = useState(false);
  const [sortModalVisible, setSortModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  ResetScroll();

  const handleModalClose = () => {
    setCategoryModalVisible(false);
    setSizeModalVisible(false);
    setSortModalVisible(false);
  };
  const handleCategoryBtnClick = () => {
    setCategoryModalVisible(true);
    setSizeModalVisible(false);
    setSortModalVisible(false);
  };

  const handleSizeBtnClick = () => {
    setCategoryModalVisible(false);
    setSizeModalVisible(true);
    setSortModalVisible(false);
  };

  const handleSortBtnClick = () => {
    setCategoryModalVisible(false);
    setSizeModalVisible(false);
    setSortModalVisible(true);
  };

  const handleNavigateCategory = (url) => {
    navigate(url);
  };

  const handleSizeChange = (sizeName) => {
    const parsedSearchParams = getParsedSearchParams();
    const sizes = parsedSearchParams.size || [];
    const updatedSizes = sizes.filter((item) => item !== sizeName);
    if (!(updatedSizes.length < sizes.length)) updatedSizes.push(sizeName);

    setSearchParams({ ...parsedSearchParams, size: updatedSizes });
  };

  const handleSortChange = (sortKey) => {
    setSortOrder(SORT_ORDER[sortKey]);
  };

  const getParsedSearchParams = () => {
    const keys = ["query", "tag", "size"];
    const parsedSearchParams = keys.reduce((parsed, key) => {
      if (searchParams.getAll(key)) {
        parsed[key] = searchParams.getAll(key);
      }
      return parsed;
    }, {});

    return parsedSearchParams;
  };

  const getLocation = (navItem) => {
    const navLocation = { ...location };
    const params = { ...getParsedSearchParams(), tag: navItem.name };

    delete params.query;

    if (params.tag === PRODUCT_TAGS.ALL_PLANTS) {
      delete params.tag;
    }

    navLocation.search = `?${createSearchParams(params)}`;

    return navLocation;
  };

  const parsedSearchParams = getParsedSearchParams();
  const sizeList = sizeListData.map((item) => {
    const active = parsedSearchParams.size.includes(item.name);
    return { ...item, active: active };
  });

  const navLinkList = navLinkData.map((item) => {
    const active = parsedSearchParams.tag[0] === item.name;
    return { ...item, active: active };
  });

  if (parsedSearchParams.tag.length === 0) {
    const allPlantsLink = navLinkList.find(
      (item) => item.name === PRODUCT_TAGS.ALL_PLANTS
    );
    allPlantsLink.active = true;
  }

  const sortList = Object.keys(SORT_ORDER).map((key) => ({
    name: key,
    text: SORT_ORDER[key],
    active: SORT_ORDER[key] === sortOrder,
  }));

  return (
    <div className="pages-shop">
      <nav className="filters">
        <h2>Filters</h2>
        <div className="filters__categories">
          <h3 className="filters__header">Category</h3>
          <ul>
            {navLinkList.map((item, index) => (
              <li key={index}>
                <Link
                  id={`filters_${item.name}`}
                  to={getLocation(item)}
                  // reloadDocument={true}
                  className={
                    item.active
                      ? "filters__link--active"
                      : "filters__link--inactive"
                  }
                >
                  {item.text}
                  <i className="fa fa-circle fa-2xs" aria-hidden="true"></i>
                </Link>
              </li>
            ))}
          </ul>
          <hr></hr>
        </div>
        <div className="filters__sizes">
          <h3 className="filters__header">Size</h3>
          <ul>
            {sizeList.map((item) => (
              <li key={item.name}>
                <button
                  className={
                    "filters__option " +
                    (item.active ? "filters--active" : "filters--inactive")
                  }
                  onClick={() => handleSizeChange(item.name)}
                >
                  <i
                    className={
                      item.active
                        ? "fa-regular fa-square-check"
                        : "fa-regular fa-square"
                    }
                  ></i>
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
          <hr></hr>
        </div>
        <div></div>
      </nav>
      <div className="content">
        <div className="control">
          <div className="control__left">
            <h1>{navLinkList.find((item) => item.active).text}</h1>
          </div>
          <div className="control__right">
            <select
              className="control__sort"
              onChange={(event) => handleSortChange(event.target.value)}
            >
              {sortList.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="content__line"></div>
        <div className="compact">
          <div className="compact__left">
            <button
              className="compact__button"
              onClick={() => {
                handleCategoryBtnClick();
              }}
            >
              <span>Category</span>
            </button>
            <button
              className="compact__button"
              onClick={() => {
                handleSizeBtnClick();
              }}
            >
              <span>Size</span>
            </button>
          </div>
          <div className="compact__right">
            <button
              className="compact__sort-btn"
              onClick={handleSortBtnClick}
              aria-label="Open filter menu"
            >
              <i className="fa-solid fa-arrow-down-wide-short"></i>
            </button>
          </div>
        </div>
        <Inventory
          query={searchParams.get("query")}
          tag={searchParams.get("tag")}
          sizes={searchParams.getAll("size")}
          sortOrder={sortOrder}
        />
      </div>
      <ListModal
        title="Filter By Category"
        listItems={navLinkList}
        isVisible={categoryModalVisible}
        handleItemSelect={(item) => {
          handleNavigateCategory(getLocation(item));
        }}
        handleCloseSelect={handleModalClose}
      />
      <ListModal
        title="Filter By Size"
        checkBoxType={true}
        listItems={sizeList}
        isVisible={sizeModalVisible}
        handleItemSelect={(item) => {
          handleSizeChange(item.name);
        }}
        handleCloseSelect={handleModalClose}
      />
      <ListModal
        title="Sort By"
        listItems={sortList}
        isVisible={sortModalVisible}
        handleItemSelect={(item) => {
          handleSortChange(item.name);
        }}
        handleCloseSelect={handleModalClose}
      />
    </div>
  );
}

export default Shop;
