import { useEffect, useRef } from "react";

function ListModal(props) {
  const {
    listItems,
    isVisible,
    handleItemSelect,
    handleCloseSelect,
    title,
    checkBoxType = false,
  } = props;
  const bodyRef = useRef(null);

  useEffect(() => {
    bodyRef.current.scrollTop = 0;

    document.body.style.overflow = !isVisible ? "" : "hidden";
    document.body.style.height = !isVisible ? "" : "100%";
    document.body.style.position = !isVisible ? "" : "fixed";
    document.body.style.left = "0";
    document.body.style.right = "0";

    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.left = "";
      document.body.style.right = "";
    };
  }, [isVisible]);

  const generateDefault = () => {
    return listItems.map((item, index) => (
      <li
        key={index}
        id={index}
        className={
          item.active
            ? "list-modal__link--active"
            : "list-modal__link--inactive"
        }
        onClick={() => handleItemSelect(item)}
      >
        {item.text}
        <i className="fa fa-circle fa-2xs" aria-hidden="true"></i>
      </li>
    ));
  };
  const generateCheckBox = () => {
    return listItems.map((item) => (
      <li key={item.name} onClick={() => handleItemSelect(item)}>
        <button
          className={
            "list-modal__checkbox " +
            (item.active
              ? "list-modal__checkbox--active"
              : "list-modal__checkbox--inactive")
          }
        >
          <i
            className={
              item.active
                ? "fa-regular fa-square-check"
                : "fa-regular fa-square"
            }
          ></i>
          {item.text}
        </button>
      </li>
    ));
  };

  let rootClass = isVisible
    ? "list-modal list-modal--visible"
    : "list-modal list-modal--invisible";

  let contentClass = isVisible
    ? "list-modal__content list-modal__content--withFX"
    : "list-modal__content list-modal__content--noFX";

  return (
    <div className={rootClass}>
      <div className={contentClass}>
        <div className="list-modal__body" ref={bodyRef}>
          <h3 className="list-modal__title">{title}</h3>
          <div className="list-modal__categories">
            <ul>{checkBoxType ? generateCheckBox() : generateDefault()}</ul>
          </div>
        </div>
        <div className="list-modal__footer" onClick={handleCloseSelect}>
          <h3>Close</h3>
        </div>
      </div>
    </div>
  );
}

export default ListModal;
