import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../components/AutoComplete";
import Loader from "../components/Loader";
import PayPalCheckout from "../components/PayPalCheckout";
import RadioSet from "../components/RadioSet";
import { EMAIL_REGEX } from "../constants/RegexValidation";
import ResetScroll from "../hooks/ResetScroll";
import useInventoryList from "../hooks/UseInventoryList";
import provincesData from "../data/provinces.json";
import deliveryInfosData from "../data/delivery-info-data.json";
import paymentTypesData from "../data/payment-types.json";

const paymentTypeInfo = [
  {
    id: "DIRECT",
    text: "Direct Transfer",
    active: true,
    description: "You've chosen direct transfer",
  },
  {
    id: "COD",
    text: "Cash on Delivery",
    active: false,
    description: "You've chosen cash on delivery",
  },
  {
    id: "PAYPAL",
    text: "Paypal",
    active: false,
    description: "You've chosen paypal",
  },
];

const initCustomerInfo = {
  firstName: "",
  lastName: "",
  streetAddress: "",
  city: "",
  email: "",
  mobile: "",
  postCode: "",
  province: "",
};

const SKEY_CART = "cartList";
const serverURI = process.env.REACT_APP_API_URL;
const mockMode = process.env.REACT_APP_API === "mock";

function Checkout() {
  const [customerInfo, setCustomerInfo] = useState(initCustomerInfo);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [deliveryInfoData, setDeliveryInfoData] = useState([]);
  const [paymentTypesInput, setPaymentTypesInput] = useState(paymentTypeInfo);
  const [isCustomerComplete, setIsCustomerComplete] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState([]);
  const cartList = JSON.parse(localStorage.getItem(SKEY_CART)) || [];
  const { inventoryList } = useInventoryList();
  const navigate = useNavigate();
  const mounted = useRef(false);

  ResetScroll();

  useEffect(() => {
    mounted.current = true;

    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
        },
        // withCredentials: true,
      };

      let provincesRes = provincesData;
      let paymentTypesRes = paymentTypesData;
      let deliveryInfoDataRes = deliveryInfosData;

      if (!mockMode) {
        const responses = await axios.all([
          axios.get(`${serverURI}/provinces`, config),
          axios.get(`${serverURI}/orders/payment-types`, config),
          axios.get(`${serverURI}/delivery-info-data`, config),
        ]);

        provincesRes = responses[0].data;
        paymentTypesRes = responses[1].data;
        deliveryInfoDataRes = responses[2].data;
      }

      setProvinces(provincesRes.data);
      setPaymentTypes(paymentTypesRes.data);
      setDeliveryInfoData(deliveryInfoDataRes.data);
    };

    fetchData().catch((error) => {
      console.log("Checkout page error. Pass me to an error handler:");
      console.log(error);
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const getProduct = (productKey) => {
    const product = inventoryList.find((item) => item.id === productKey);
    return product;
  };

  const calculateSubtotals = () => {
    let subtotal = 0;
    if (inventoryList.length > 0 && cartList.length > 0) {
      subtotal = cartList.reduce(function (workingSubTotal, item2) {
        const product = getProduct(item2.productKey);
        return workingSubTotal + product.price * item2.quantity;
      }, 0);
    }
    return subtotal;
  };

  const calculateDeliveryFee = () => {
    let deliveryFee = 0;

    const deliveryInfoDataItems = deliveryInfoData.filter((item) => {
      const provinceData = provinces.find(
        (province) => province.attributes.name === customerInfo.province
      );
      return item.relationships.destination.data.id === provinceData?.id;
    });

    const sortedDeliveryInfoDataItems = deliveryInfoDataItems.sort((a, b) => {
      return a.attributes.size - b.attributes.size;
    });

    if (sortedDeliveryInfoDataItems.length > 0) {
      deliveryFee = cartList.reduce(function (workingValue, item) {
        const product = getProduct(item.productKey);

        const productDeliveryDataItem = sortedDeliveryInfoDataItems.find(
          (item) => product.sizeValue <= item.attributes.size
        );

        const productDeliveryFee =
          productDeliveryDataItem?.attributes?.price || 0;

        return workingValue + productDeliveryFee;
      }, 0);
    }

    return deliveryFee;
  };

  const onCustomerEdit = (event) => {
    event.preventDefault();
    setIsCustomerComplete(false);
  };

  const onCustomerContinue = (event) => {
    event.preventDefault();

    window.scrollTo(0, 0);

    if (submitErrors.length > 0) return;

    const completeErrors = validateInfoComplete(customerInfo);
    if (completeErrors.length > 0) {
      setSubmitErrors(completeErrors);
      return;
    }

    setIsCustomerComplete(true);
  };

  const onFormInputChange = (name, value, validate = false) => {
    const updatedCustomerInfo = { ...customerInfo };
    updatedCustomerInfo[name] = value;
    setCustomerInfo(updatedCustomerInfo);

    if (validate) {
      validateFormPreSubmit(updatedCustomerInfo);
    }
  };

  const onPaymentChange = (event, itemID) => {
    event.preventDefault();
    const updatedItemList = paymentTypeInfo.map((item) => {
      const updatedItem = { ...item };
      updatedItem.active = item.id === itemID ? true : false;
      return updatedItem;
    });

    setPaymentTypesInput(updatedItemList);
  };

  const createServerOrder = async () => {
    const config = {
      headers: {
        "Content-Type": "application/vnd.api+json",
        "Accept": "application/vnd.api+json",
      },
      // withCredentials: true,
    };

    const cleanInfo = cleanFormInfo(customerInfo);
    const provinceData = provinces.find(
      (province) => province.attributes.name === cleanInfo.province
    );
    const customerData = {
      data: {
        type: "customer",
        attributes: {
          firstName: cleanInfo.firstName,
          lastName: cleanInfo.lastName,
          streetAddress: cleanInfo.streetAddress,
          city: cleanInfo.city,
          email: cleanInfo.email,
          mobile: cleanInfo.mobile,
          postCode: cleanInfo.postCode,
        },
        relationships: {
          province: {
            data: {
              type: provinceData.type,
              id: provinceData.id,
            },
          },
        },
      },
    };

    const response = await axios.post(
      `${serverURI}/customers`,
      customerData,
      config
    );
    const customerPostRes = response.data;

    const userPaymentType = paymentTypesInput.find((type) => type.active);
    const paymentType = paymentTypes.find(
      (type) => type.attributes.name === userPaymentType.id
    );

    const userOrders = cartList.map((item) => {
      return {
        product: item.productKey,
        quantity: item.quantity,
      };
    });

    const orderData = {
      data: {
        type: "order",
        attributes: {
          orderItems: userOrders,
        },
        relationships: {
          customer: {
            data: {
              type: customerPostRes.data.type,
              id: customerPostRes.data.id,
            },
          },
          paymentType: {
            data: {
              type: paymentType.type,
              id: paymentType.id,
            },
          },
        },
      },
    };

    return axios.post(`${serverURI}/orders`, orderData, config);
  };

  const completePurchase = () => {
    localStorage.removeItem(SKEY_CART);
    navigate("/thanks");
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    setTransactionLoading(true);

    if (mockMode) {
      completePurchase();
      setTransactionLoading(false);
      return;
    }

    createServerOrder()
      .then(function (response) {
        if (mounted.current) {
          completePurchase();
        } else {
          console.log("Transaction cancelled");
        }
        setTransactionLoading(false);
      })
      .catch(function (error) {
        setTransactionLoading(false);
        console.log("Checkout POST error. Pass me to an error handler:");
        console.log(error);
      });
  };

  const provinceSelections = provinces.map(
    (province) => province.attributes.name
  );

  const cleanFormInfo = (formInfo) => {
    const cleaned = Object.keys(formInfo).reduce((accumulator, key) => {
      accumulator[key] = formInfo[key].trim();
      return accumulator;
    }, {});

    return cleaned;
  };

  const validateFormPreSubmit = (inputInfo) => {
    const cleanInfo = cleanFormInfo(inputInfo);
    const inputInfoErrors = validateInputValues(cleanInfo);
    setSubmitErrors(inputInfoErrors);
  };

  const validateInfoComplete = (info) => {
    const cleanInfo = cleanFormInfo(info);

    let errors = [];
    Object.keys(cleanInfo).forEach((key) => {
      if (!cleanInfo[key]) {
        errors.push({
          name: key,
          message: "Please fill in this field.",
        });
      }
    });

    return errors;
  };

  const validateInputValues = (info) => {
    let errors = [];

    //validate email
    if (info.email) {
      const email = info.email.toLowerCase();
      const emailRE = EMAIL_REGEX;
      const validEmail = email.match(emailRE);
      if (!validEmail) {
        errors.push({
          name: "email",
          message: "Please enter a valid email address.",
        });
      }
    }

    //validate mobile
    if (info.mobile) {
      const mobile = info.mobile;
      const mobileRE = /^(\+63|0)\d{10}$/;
      const validMobile = mobile.match(mobileRE);
      if (!validMobile) {
        errors.push({
          name: "mobile",
          message: "Please enter a valid mobile number.",
        });
      }
    }

    //validate postal code
    if (info.postCode) {
      const postal = info.postCode;
      const postalRE = /^\d{4}$/;
      const validPostal = postal.match(postalRE);
      if (!validPostal) {
        errors.push({
          name: "postCode",
          message: "Please enter a valid postal code.",
        });
      }
    }

    //validate province
    if (info.province) {
      const province = info.province;
      const validProvince = provinceSelections.find(
        (provinceItem) => provinceItem === province
      );
      if (!validProvince) {
        errors.push({
          name: "province",
          message: "Please enter a valid province or state.",
        });
      }
    }

    return errors;
  };

  const renderFullCustomerSection = () => {
    const inputInfo = [
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
      },
      {
        name: "mobile",
        type: "tel",
        placeholder: "Mobile Number",
      },
      {
        name: "streetAddress",
        type: "text",
        placeholder: "Street address, apartment, suite, floor, etc",
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
      },
      {
        name: "postCode",
        type: "text",
        placeholder: "Postal Code",
      },
    ];

    const getInfo = (name) => {
      return inputInfo.find((info) => info.name === name);
    };

    const getError = (name) => {
      return submitErrors.find((error) => error.name === name);
    };

    const renderInputField = (info) => {
      return (
        <Fragment>
          <input
            className={
              getError(info.name)
                ? "billing__field billing__field--error"
                : "billing__field billing__field--default"
            }
            type={info.type}
            name={info.name}
            value={customerInfo[info.name]}
            placeholder={info.placeholder}
            onChange={(event) =>
              onFormInputChange(event.target.name, event.target.value)
            }
            onBlur={() => validateFormPreSubmit(customerInfo)}
          />
          {getError(info.name) && (
            <div className="billing__field-error-info">
              {getError(info.name).message}
            </div>
          )}
        </Fragment>
      );
    };

    return (
      <div className="billing__full billing__customer">
        <h3 className="billing__subheader">Customer Information</h3>
        <div className="billing__group">
          <label>Full Name:</label>
          {renderInputField(getInfo("firstName"))}
          {renderInputField(getInfo("lastName"))}
        </div>
        <div className="billing__group">
          <label>Contact Information:</label>
          {renderInputField(getInfo("email"))}
          {renderInputField(getInfo("mobile"))}
        </div>
        <div className="billing__group">
          <label>Address</label>
          {renderInputField(getInfo("streetAddress"))}
          {renderInputField(getInfo("city"))}
          {renderInputField(getInfo("postCode"))}
        </div>
        <AutoComplete
          selections={provinceSelections}
          value={customerInfo.province}
          onChange={onFormInputChange}
          inputError={getError("province")}
        />
        {getError("province") && (
          <div className="billing__field-error-info">
            {getError("province").message}
          </div>
        )}
        <div className="billing__footer">
          <button className="billing__button" onClick={onCustomerContinue}>
            Continue
          </button>
        </div>
      </div>
    );
  };

  const renderPreviewCustomerSection = () => {
    return (
      <div className="preview">
        <div className="preview__header">
          <h3>Customer Information</h3>
          <button
            className={
              transactionLoading
                ? "preview__edit preview__edit--disabled"
                : "preview__edit preview__edit--enabled"
            }
            onClick={onCustomerEdit}
            disabled={transactionLoading}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
        </div>
        <div className="preview__info">
          <div>
            <i className="fa-regular fa-circle-check fa-lg"></i>
          </div>
          <div>
            <div className="preview__info-label">Full Name:</div>
            <div className="preview__info-body">{`${customerInfo.lastName}, ${customerInfo.firstName}`}</div>
          </div>
        </div>
        <div className="preview__info">
          <div>
            <i className="fa-regular fa-circle-check fa-lg"></i>
          </div>
          <div>
            <div className="preview__info-label">Contact Info:</div>
            <div className="preview__info-body">
              <div>{`${customerInfo.email}`}</div>
              <div>{`${customerInfo.mobile}`}</div>
            </div>
          </div>
        </div>
        <div className="preview__info">
          <div>
            <i className="fa-regular fa-circle-check fa-lg"></i>
          </div>
          <div>
            <div className="preview__info-label">Address: </div>
            <div className="preview__info-body">
              <div>
                {`${customerInfo.streetAddress}, ${customerInfo.city}, ${customerInfo.province}`}
              </div>
              <div>{`${customerInfo.postCode}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFullPaymentSection = () => {
    const activePaymentType = paymentTypesInput.find((item) => item.active);

    return (
      <div className="billing__full payment">
        <div className="payment__header">
          <h3>Payment Type</h3>
        </div>
        <RadioSet
          itemProps={paymentTypesInput}
          onChange={onPaymentChange}
          disabled={transactionLoading}
        />
        <div className="payment__desc">
          <div>{activePaymentType.description}</div>
          <div className="payment__footer">
            {transactionLoading ? (
              <Loader />
            ) : activePaymentType.id === "PAYPAL" ? (
              <PayPalCheckout
                inventoryList={inventoryList}
                cartList={cartList}
                customerInfo={customerInfo}
                createServerOrder={createServerOrder}
                completePurchase={completePurchase}
                shippingFee={deliveryFee}
              />
            ) : (
              <button className="payment__btn" type="submit">
                Place Order
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const calculateTotalItems = () => {
    const totalItems = cartList.reduce((total, item) => {
      return (total += item.quantity);
    }, 0);

    let suffix = totalItems === 1 ? "item" : "items";

    return `${totalItems} ${suffix}`;
  };

  const subtotals = calculateSubtotals();

  const deliveryFee = calculateDeliveryFee();
  const totals = subtotals + deliveryFee;

  return (
    <div className="pages-checkout">
      <div className="cart-summary">
        <div className="cart-summary__header">
          <h3>Shopping Cart</h3>
          <span className="cart-summary__cart-count">
            {calculateTotalItems()}
          </span>
        </div>
        <div className="cart-summary__body">
          <div className="cart-summary__list">
            <div>
              <div>Subtotal</div>
              <div className="cart-summary__price">{`₱${Number(
                subtotals
              ).toLocaleString("en-US")}`}</div>
            </div>
            <div>
              <div>Delivery</div>
              <div className="cart-summary__price">
                {`₱${Number(deliveryFee).toLocaleString("en-US")}`}
              </div>
            </div>
            {!customerInfo.province && (
              <div className={"cart-summary__delivery-info"}>
                {"( Fill out province detail for delivery fee calculation )"}
              </div>
            )}
          </div>

          <div className="cart-summary__total">
            <h4>TOTAL</h4>
            <div className="cart-summary__total-price">{`₱${Number(
              totals
            ).toLocaleString("en-US")}`}</div>
          </div>
        </div>
      </div>
      <div className="billing">
        <h2 className="billing__header">Billing Details</h2>
        <form className="billing__form" action="" onSubmit={onFormSubmit}>
          {isCustomerComplete
            ? renderPreviewCustomerSection()
            : renderFullCustomerSection()}
          {isCustomerComplete && renderFullPaymentSection()}
        </form>
      </div>
    </div>
  );
}

export default Checkout;
