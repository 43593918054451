import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "./../constants/AppURL";

function Modal(props) {
  const { product, isVisible, closeBtnOnClick } = props;

  useEffect(() => {
    document.body.style.overflow = !isVisible ? "" : "hidden";
    document.body.style.height = !isVisible ? "" : "100%";
    document.body.style.position = !isVisible ? "" : "fixed";
    document.body.style.left = "0";
    document.body.style.right = "0";

    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.left = "";
      document.body.style.right = "";
    };
  }, [isVisible]);

  let modalClass = isVisible
    ? "modal modal--visible"
    : "modal modal--invisible";

  let modalContentClass = isVisible
    ? "modal__content modal__content--withFX"
    : "modal__content modal__content--noFX";

  return (
    <div id="product" className={modalClass}>
      <div className={modalContentClass}>
        <div className="modal__header">
          <button
            className="modal__icon-btn"
            onClick={closeBtnOnClick}
            aria-label="Close this pop-up"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <div className="modal__body">
          <div className="modal__image">
            <div className="modal__check-icon">
              <i className="fa-solid fa-circle-check fa-xl"></i>
            </div>
            <picture>
              <source
                srcSet={`${SERVER_URL}/images/${product.image}`}
                type="image/webp"
              />
              <source
                srcSet={`${SERVER_URL}/images/${product.image?.replace(
                  ".webp",
                  ".jpg"
                )}`}
                type="image/jpeg"
              />
              <img
                className="inventory__image"
                alt={`Preview of ${product.name}`.toLowerCase()}
                src={`${SERVER_URL}/images/${product.image?.replace(
                  ".webp",
                  ".jpg"
                )}`}
                width={400}
                height={518}
              />
            </picture>
          </div>
          <div className="modal__detail">
            <h4 className="modal__product-header">Added to Cart</h4>
            <div className="modal__product-label">{product.name}</div>
            <div className="modal__product-price">{`₱${Number(
              product.price
            ).toLocaleString("en-US")}`}</div>
          </div>
        </div>
        <div className="modal__footer">
          <Link to="/cart" className="modal__view-cart-btn">
            View Cart
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Modal;
