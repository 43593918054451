import { useState } from "react";
import ResetScroll from "../hooks/ResetScroll";

const faqInfo = [
  {
    name: "question1",
    question: "Do I have to repot my plant once it arrives?",
    answer:
      "It's best to let the plant acclimate to its new environment first. You can repot it after two weeks.",
    active: false,
  },
  {
    name: "question2",
    question: "How soon can I expect my delivery?",
    answer:
      "We take 3 to 5 business days to fulfill a delivery. We send emails to update the status of your order.",
    active: false,
  },
  {
    name: "question3",
    question: "Do you allow plant rentals?",
    answer:
      "Currently our store process does not have proper support for plant rentals." +
      " We are looking into the service and may provide it in the future.",
    active: false,
  },
];

function Faq() {
  const [itemList, setItemList] = useState(faqInfo);

  ResetScroll();

  const onPreviewClick = (itemName) => {
    const updatedItems = itemList.map((item) => {
      const updatedItem = { ...item };
      if (item.name === itemName) {
        updatedItem.active = !updatedItem.active;
      }
      return updatedItem;
    });

    setItemList(updatedItems);
  };

  return (
    <div className="pages-faq">
      <div className="header">
        <h1>Common Questions</h1>
      </div>
      <div className="collapse-list">
        <ul>
          {itemList.map((item) => {
            return (
              <li
                key={item.name}
                className={
                  item.active
                    ? "list-item list-item--active"
                    : "list-item list-item--inactive"
                }
                onClick={() => {
                  onPreviewClick(item.name);
                }}
              >
                <div className="list-item__preview">
                  <h2 className="list-item__title">{item.question}</h2>
                  <i
                    className={
                      item.active ? "fa-solid fa-minus" : "fa-solid fa-plus"
                    }
                  ></i>
                </div>
                <p className="list-item__content">{item.answer}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Faq;
