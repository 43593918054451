import axios from "axios";
import { useEffect } from "react";
import Banner from "../components/Banner";
import Coverline from "../components/Coverline";

function Home() {
  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
        },
        // withCredentials: true,
      };

      if (process.env.REACT_APP_API !== "mock") {
        const serverURI = process.env.REACT_APP_API_URL;
        await axios.get(`${serverURI}/products/tags`, config);
      }
    };

    fetchData().catch((error) => {
      console.log("Order page error. Pass me to an error handler:");
      console.log(error);
    });
  }, []);

  return (
    <div className="pages-home">
      <Banner />
      <Coverline />
    </div>
  );
}

export default Home;
